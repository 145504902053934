<template>
  <v-container>
    <BaseScreenHeader
      title="Weight Loss/Gain"
      width="90%"
      screenInfo="CLC-007"
    />
    <v-card-subtitle :class="textSizeXSmall + ' silver--text py-0 mt-5 px-0'">
      Use this calculator to better plan/estimate your weight loss/gain. See
      projections regarding your journey to your ideal body composition. Enter
      your current weight and the desired ideal weight.
    </v-card-subtitle>
    <v-card-subtitle :class="textSizeXSmall + ' silver--text pb-0 px-0'">
      We will calculate the number of days needed to reach your goal using the
      recommended 500kcal/day deficit/excess or the more aggressive 750kcal/day.
    </v-card-subtitle>
    <v-sheet class="charcoal">
      <BaseLoginInvite />

      <v-card-actions class="pr-0 py-0">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            class="charcoal paper--text mr-n2"
            dark
            :disabled="!weight > 0 && !idealWeight > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>
      <v-sheet rounded color="transparent pa-2" :style="charcoalTileMenu">
        <v-row dense>
          <v-col v-if="showGender" cols="12" md="4">
            <v-radio-group
              dark
              v-model="mySex"
              row
              tabindex="1"
              @change="reset"
            >
              <v-radio :label="`Female`" value="Female"></v-radio>
              <v-radio :label="`Male`" value="Male"></v-radio>
            </v-radio-group>
          </v-col>
          <v-spacer />
          <v-col cols="6" md="3">
            <v-text-field
              dark
              :label="'Weight(' + unit(metric) + ')'"
              class="enlarged-input-small"
              ref="weight"
              v-model="weight"
              type="number"
              prepend-icon="mdi-weight"
              @input="calculateIdeal"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="3">
            <v-text-field
              dark
              :label="'Ideal Weight(' + unit(metric) + ')'"
              class="enlarged-input-small"
              ref="idealWeight"
              v-model="idealWeight"
              type="number"
              prepend-icon="mdi-weight"
              @input="calculateIdeal"
              @focus="$event.target.select()"
            />
          </v-col>
        </v-row>
      </v-sheet>

      <template v-if="idealWeight > 0 && weight > 0">
        <v-card-actions class="pb-0 px-0 mb-2 mt-4 paper--text">
          <span :class="textSizeSmall">
            Difference
          </span>
          <v-spacer />
          <span :class="textSize + ' progressActive--text'">
            {{ metric ? diffMetric : diff }} {{ unit(metric) }}
          </span>
        </v-card-actions>
      </template>
      <template v-if="idealWeight > 0 && weight > 0">
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Extra calories to {{ this.diff > 0 ? 'lose' : 'gain' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyNumber(caloriesToLose) }} kcal
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Days to goal @ 500kcal/day
            {{ this.diff > 0 ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal500 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal500) }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Days to goal @ 750kcal/day
            {{ this.diff > 0 ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal750 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal750) }}
          </span>
        </v-card-actions>
      </template>
    </v-sheet>
  </v-container>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    mySex: '',
    male: false,
    female: false,
    weight: 0,
    weightLbs: 0,
    weightKg: 0,
    idealWeight: 0,
    idealWeightLbs: 0,
    idealWeightKg: 0,
    showGender: true,
    fatToLose: 0,
    diff: 0,
    diffMetric: 0,
    daysToGoal500: 0,
    daysToGoal750: 0,
    dateToGoal500: null,
    dateToGoal750: null,
    caloriesToLose: 0,
    basicMeasures: null,
    caloriesPerPound: 3500,
    toLbs: 2.20462,
    measureList: [],
    measurements: []
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mySex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.weight.focus())
    if (this.loggedIn) this.loadData()
  },
  validations: {},
  computed: {
    lengthUnit() {
      return this.metric ? 'cm' : 'inches'
    },
    ...appConfig
  },
  methods: {
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },
    getMeasureId(what) {
      let id = 0
      this.measureList.forEach(el => {
        if (el.name.toLowerCase() == what) id = el.id
      })
      return id
    },
    setMeasures() {
      this.measurements = []
      this.measurements.push({
        measurementid: this.getMeasureId('weight'),
        imperial: this.weightLbs,
        metric: this.weightKg,
        measured: this.now()
      })
    },
    reset() {
      this.weight = 0
      this.idealWeight = 0
      this.$nextTick(() => this.$refs.weight.focus())
    },
    switchMe() {
      this.reset()
      this.$nextTick(() => this.$refs.weight.focus())
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }

                this.calculateIdeal()
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.weight > 0) return
      this.setMeasures()

      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.mySex = ''
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },

    calculateIdeal() {
      this.convertWeights()
      this.diff = Number(this.weightLbs - this.idealWeightLbs)
      if (this.metric)
        this.diffMetric = this.twoDigits(this.weight - this.idealWeight)
      else this.diffMetric = this.twoDigits(this.diff / this.toLbs)
      this.diff = this.twoDigits(this.diff)

      this.caloriesToLose = Math.floor(this.caloriesPerPound * this.diff)

      this.daysToGoal500 = Math.floor(
        Number(this.caloriesPerPound / 500) * this.diff
      )
      this.daysToGoal750 = Math.floor(
        Number(this.caloriesPerPound / 750) * this.diff
      )

      this.dateToGoal500 = new Date()
      this.dateToGoal500.setDate(
        this.dateToGoal500.getDate() + this.daysToGoal500
      )
      this.dateToGoal750 = new Date()
      this.dateToGoal750.setDate(
        this.dateToGoal750.getDate() + this.daysToGoal750
      )
    },
    outOfRange() {
      return this.weight == 0 || this.idealWeight == 0
    },
    convertWeights() {
      if (this.metric) {
        this.weightLbs = this.twoDigits(this.weight * this.toLbs)
        this.weightKg = this.twoDigits(this.weight)
        this.idealWeightLbs = this.twoDigits(this.idealWeight * this.toLbs)
        this.idealWeightKg = this.twoDigits(this.idealWeight)
      } else {
        this.weightKg = this.twoDigits(this.weight / this.toLbs)
        this.weightLbs = this.twoDigits(this.weight)
        this.idealWeightKg = this.twoDigits(this.idealWeight / this.toLbs)
        this.idealWeightLbs = this.twoDigits(this.idealWeight)
      }
    },
    calculate() {
      this.calculateIdeal()
    }
  }
}
</script>
