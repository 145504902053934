<template>
  <v-container>
    <BaseScreenHeader
      title="Creatine Dosage"
      width="90%"
      screenInfo="CLC-008"
    />
    <v-card-subtitle :class="textSizeXSmall + ' py-0 mt-5 silver--text'">
      Use this calculator to calculate your daily creatine intake based on your
      body weight, for the loading and maintenance stages. Check below for more
      information about creatine's role in muscle development.
    </v-card-subtitle>
    <v-card-subtitle :class="textSizeXSmall + ' pt-0 silver--text'">
      Since your body has a limited capacity of absorption, in the loading
      phase, do not take more than 5g at a time. The loading phase is not
      recommended for people over 70 years old because of increased
      susceptibility to kidney disfunction. You can completely skip the loading
      phase, it will just take longer for your muscles to get saturated.
    </v-card-subtitle>
    <v-sheet class="charcoal px-2">
      <BaseLoginInvite />
      <v-card-actions class="pa-0">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            class="paper--text mr-n2"
            dark
            :disabled="!weight > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>

      <v-sheet
        rounded
        color="transparent"
        class="pa-2 mb-5"
        :style="charcoalTileMenu"
      >
        <v-spacer />
        <v-text-field
          dark
          :label="'Weight(' + unit(metric) + ')'"
          class="enlarged"
          ref="weight"
          v-model="weight"
          type="number"
          prepend-icon="mdi-weight"
          @input="calculate"
          @focus="$event.target.select()"
        />
      </v-sheet>
      <template v-if="withLoading">
        <v-card-actions v-if="weight > 0" class="pa-0 mb-2 paper--text">
          <span :class="textSizeSmall">
            Loading phase dosage
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ loadingDosage }} grams/day
          </span>
        </v-card-actions>
      </template>
      <v-card-actions v-if="weight > 0" class="pa-0 mb-8 paper--text">
        <span :class="textSizeSmall">
          Maintenance phase dosage
        </span>
        <v-spacer />
        <span :class="textSizeSmall + ' progressActive--text'">
          {{ maintenanceDosage }} grams/day
        </span>
      </v-card-actions>
      <h6 class="paper--text">What is Creatine?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        Creatine is a substance that is found naturally in muscle cells and is
        one of the most well researched supplements on the sports nutrition
        market. It helps your muscles produce energy during heavy lifting or
        high-intensity exercise. It is therefore very popular among bodybuilders
        and has proven benefits to support endurance and recovery.
      </v-card-subtitle>
      <h6 class="paper--text">How Much Creatine Should You Take?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        The above calculator helps you figure out the dosage based on your
        weight. The recommended daily amount of creatine can range from anything
        between 5 g to 20 g. Due to limitations in your body's capacity of
        absorbtion, you should not take more than 5g at a time.
      </v-card-subtitle>
      <h6 class="paper--text">When should you take Creatine?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        You can take Creatine both before a workout to increase performance or
        after a workout to improve recovery and support growth. Just don't go
        over the recommended dosage.
      </v-card-subtitle>
      <h6 class="paper--text">What is Loading?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        First, it is worth noting that the loading phase is optional. The
        loading phase lasts 5-6 days, during which you take a higher dose of
        Creatine, which will help you get your muscles saturated with creatine
        faster. Also note, that as described above, the body can only absorb
        that much at a time, so don't take more than 5g in one sitting.
      </v-card-subtitle>
      <h6 class="paper--text">What is Maintenance?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        Once your muscles are saturated with Creatine, you will need a much
        lower dose to maintain the level, usually just a few grams a day.
      </v-card-subtitle>
      <h6 class="paper--text">When should you take Creatine?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        You can take Creatine both before a workout to increase performance or
        after a workout to improve recovery and support growth. Just don't go
        over the recommended dosage.
      </v-card-subtitle>
      <h6 class="paper--text">What Happens If You Miss a Day?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        Nothing, really. Your Creatine store won't vanish if you miss a day or
        two. It takes about a month for your Creatine stores to return back to
        normal.
      </v-card-subtitle>
      <h6 class="paper--text">When to Stop Taking Creatine?</h6>
      <v-card-subtitle :class="textSizeSmall + ' silver--text pb-3 pt-0 px-0'">
        After four to six weeks of supplementation, take a month break to allow
        your body's Creatine levels to reset back to normal.
      </v-card-subtitle>
      <v-card-text class="px-0">
        <a
          href="/pdf/infographics/HowCreatineWorks-bfit.fit.pdf"
          title="How does Creatine work? Infographic"
          target="_blank"
        >
          <v-img
            :src="
              require('@/assets/infographics/HowCreatineWorks-bfit.fit.png')
            "
            alt="How does Creatine work? Infographic"
            title="How does Creatine work? Infographic"
            contain
          />
        </a>
      </v-card-text>
    </v-sheet>
  </v-container>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    weight: 0,
    weightLbs: 0,
    weightKg: 0,
    toLbs: 2.20462,
    loadingDosage: 0,
    maintenanceDosage: 0,
    loadingCoeficient: 0.3,
    maintenanceCoeficient: 0.03,
    withLoading: true,
    measureList: [],
    measurements: []
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mysex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.weight.focus())
    if (this.loggedIn) this.loadData()
  },
  validations: {},
  computed: {
    ...appConfig
  },
  methods: {
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },
    getMeasureId(what) {
      let id = 0
      this.measureList.forEach(el => {
        if (el.name.toLowerCase() == what) id = el.id
      })
      return id
    },
    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      this.measurements.push({
        measurementid: this.getMeasureId('weight'),
        imperial: this.weightLbs,
        metric: this.weightKg,
        measured: timestamp
      })
    },
    reset() {
      this.weight = 0
      this.$nextTick(() => this.$refs.weight.focus())
    },
    switchMe() {
      this.reset()
      this.$nextTick(() => this.$refs.weight.focus())
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                this.calculate()
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.weight > 0) return
      this.setMeasures()

      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    },
    outOfRange() {
      return this.weight == 0
    },
    convertWeights() {
      if (this.metric) {
        this.weightLbs = this.twoDigits(this.weight * this.toLbs)
        this.weightKg = this.twoDigits(this.weight)
      } else {
        this.weightKg = this.twoDigits(this.weight / this.toLbs)
        this.weightLbs = this.twoDigits(this.weight)
      }
    },
    calculate() {
      this.convertWeights()
      let loadingDosage = this.twoDigits(this.loadingCoeficient * this.weightKg)
      if (loadingDosage > 20) this.loadingDosage = 20
      else this.loadingDosage = loadingDosage

      this.maintenanceDosage = this.twoDigits(
        this.maintenanceCoeficient * this.weightKg
      )
    }
  }
}
</script>
