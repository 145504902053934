<template>
  <v-container>
    <BaseScreenHeader title="Reverse 1RM" width="90%" screenInfo="CLC-002" />

    <v-card-subtitle :class="textSizeXSmall + ' silver--text py-0 mt-5 px-0'">
      <div class="title mb-2 paper--text">
        Reverse One Rep Max Calculator
      </div>
      Enter your 1RM and the system will calculate the number of reps and
      associated weight based for percentages of your 1RM. We will also
      calculate your 2RM to 12RM based on your 1RM. This calculator is more
      accurate for higher weights. The minimum weight is 80.
    </v-card-subtitle>
    <v-sheet class="charcoal pt-2">
      <v-sheet rounded color="transparent px-2 ">
        <v-row dense>
          <v-col cols="5">
            <v-switch
              dark
              v-model="metric"
              :label="'Metric?'"
              @change="switchMe"
              color="progressActive"
            />
          </v-col>
          <v-spacer />
          <v-col cols="7">
            <v-text-field
              dark
              :label="'1RM (' + weightUnit(metric) + ')'"
              class="enlarged-input-small"
              ref="oneRM"
              v-model="oneRM"
              type="number"
              prepend-icon="mdi-weight"
              tabindex="1"
              @focus="$event.target.select()"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-card
        tile
        flat
        :style="charcoalTileMenu"
        class="transparent mb-2 mt-2 silver--text"
        v-for="onermData in onermTypes"
        :key="onermData.value"
      >
        <v-card-actions class="py-0">
          <span :class="textSizeLarge + ' success--text'">
            {{ onermData.value }}%
          </span>
          <v-spacer />
          <v-card-text :class="textSizeSmall + ' pr-0'">
            <v-card-actions class="pa-0">
              Reps (estimated):
              <v-spacer />
              {{ onermData.reps }}
            </v-card-actions>
            <v-spacer />
            <v-card-actions class="progressActive--text pa-0">
              Reps (calculated):
              <v-spacer />
              {{ calculateReps(onermData.value) }}
            </v-card-actions>
            <v-spacer />
            <v-card-actions class="progressActive--text pa-0">
              <span :class="textSizeSmall">Weight (calculated):</span>
              <v-spacer />
              {{ oneRMPercentage(onermData.value) }}
              {{ unit(metric) }}
            </v-card-actions>
            <v-card-actions class="pa-0">
              <span :class="textSizeSmall">Weight (rounded):</span>
              <v-spacer />
              {{ oneRMPercentageFive(onermData.value) }}
              {{ unit(metric) }}
            </v-card-actions>
            <v-spacer />
          </v-card-text>
        </v-card-actions>
      </v-card>
      <v-card-subtitle :class="textSizeSmall + ' paper--text mt-2 pb-0 px-0'">
        Estimated Reps Based on %1RM
      </v-card-subtitle>
      <v-divider />
      <v-simple-table class="transparent silver--text">
        <thead>
          <tr>
            <th>
              <span :class="textSizeSmall + ' silver--text'">%1RM</span>
            </th>
            <th :class="textSizeSmall + ' text-left progressActive--text'">
              RM(X)
            </th>
            <th class="text-left title progressActive--text">
              <span class="caption"> {{ weightUnit(metric) }}</span>
            </th>
            <th class="text-left title progressActive--text">
              <span class="caption"> 5{{ weightUnit(metric) }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="onermData in reverseonermTypes" :key="onermData.value">
            <td>
              <span :class="textSizeSmall">{{ onermData.value }}</span>
            </td>
            <td :class="textSizeSmall">{{ onermData.reps }} RM</td>
            <td :class="textSizeSmall + ' progressActive--text'">
              {{ oneRMPercentage(onermData.value) }}
            </td>
            <td :class="textSizeSmall + ' progressActive--text'">
              {{ oneRMPercentageFive(onermData.value) }}
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-sheet>
  </v-container>
</template>

<script>
//import axios from 'axios'
import onermTypes from '@/json/onermtypes.json'
import reverseonermTypes from '@/json/reverseonermtypes.json'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    oneRM: 0,
    onermTypes: onermTypes,
    reverseonermTypes: reverseonermTypes
  }),
  beforeMount() {
    if (this.loggedIn) this.metric = this.useMetric
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.oneRM.focus())
  },
  validations: {},

  methods: {
    switchMe() {
      this.weight = 0
      this.$nextTick(() => this.$refs.oneRM.focus())
    },
    calculateReps(percent) {
      if (this.oneRM <= 80) return 'NA'
      let calc = this.oneRMPercentageFive(percent)
      let reps = (this.oneRM / calc - 1) * 30
      reps = Math.round(reps)
      return reps
    },
    oneRMPercentageFive(val) {
      let calc = this.oneRM * (val / 100)
      calc = Math.round(calc / 5) * 5
      return calc
    },
    oneRMPercentage(val) {
      let calc = this.oneRM * (val / 100)
      calc = Math.round(calc)
      return calc
    }
  }
}
</script>
