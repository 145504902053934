<template>
  <v-container>
    <BaseScreenHeader title="BFP Calculator" width="90%" screenInfo="CLC-006" />

    <v-card-subtitle :class="textSizeXSmall + ' silver--text py-0 mt-5'">
      To calculate your Body Fat Percentage, please enter your sex, age, and
      measurements for weight, height, waist, neck, and, for women, hips. This
      calculator is using the U.S. Navy method and utilizes a formula developed
      by Hodgdon and Beckett at the Naval Health Research Center.
    </v-card-subtitle>
    <v-card-subtitle :class="textSizeXSmall + ' silver--text pt-0'">
      <ul>
        <li>
          When measuring the waist, measure
          <b> around the navel for men </b> (the widest width) and at the
          <b>narrowest width for women</b>. For a more accurate measurement,
          don't pull in your stomach and ensure the tape measure is not that
          tight that it can't move.
        </li>
        <li>
          When measuring the neck, start below the larynx, with the tape sloping
          downward to the front. Avoid flaring your neck outwards.
        </li>
        <li>
          <b>If you are a woman</b>, measure the circumference of your hips at
          their widest point.
        </li>
      </ul>
    </v-card-subtitle>
    <v-sheet class="charcoal">
      <v-card-actions class="px-0 py-0">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            class="paper--text mr-n3"
            dark
            :disabled="!bfp > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>
      <v-sheet rounded color="transparent px-2 mb-4" :style="charcoalTileMenu">
        <v-row class="px-2" dense>
          <v-spacer v-if="!showGender" />
          <v-col v-if="showGender" cols="12" md="3">
            <v-radio-group dark v-model="mySex" row @change="reset">
              <v-radio label="Female" value="Female" />
              <v-radio label="Male" value="Male" />
            </v-radio-group>
          </v-col>
          <v-spacer />
          <v-col cols="6" md="2">
            <v-text-field
              dark
              label="Age (Years)"
              class="enlarged-input-small"
              ref="age"
              v-model="age"
              type="number"
              prepend-icon="mdi-clock"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              dark
              :label="'Weight(' + unit(metric) + ')'"
              class="enlarged-input-small"
              ref="weight"
              v-model="weight"
              type="number"
              prepend-icon="mdi-weight"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <template v-if="!metric">
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(feet)"
                class="enlarged-input-small"
                ref="feet"
                v-model="feet"
                type="number"
                prepend-icon="mdi-tape-measure"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(inches)"
                class="enlarged-input-small"
                ref="inches"
                v-model="inches"
                type="number"
                prepend-icon="mdi-tape-measure"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(cm)"
                class="enlarged-input-small"
                ref="cm"
                v-model="cm"
                type="number"
                prepend-icon="mdi-tape-measure"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
          </template>
        </v-row>
        <v-row dense>
          <v-spacer />
          <v-col cols="6" md="4">
            <v-text-field
              dark
              :label="'Waist(' + lengthUnit + ')'"
              class="enlarged-input-small"
              ref="waist"
              v-model="waist"
              type="number"
              prepend-icon="mdi-tape-measure"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="4">
            <v-text-field
              dark
              :label="'Neck(' + lengthUnit + ')'"
              class="enlarged-input-small"
              ref="neck"
              v-model="neck"
              type="number"
              prepend-icon="mdi-tape-measure"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col v-if="mySex == 'Female'" cols="6" md="2">
            <v-text-field
              dark
              :label="'Hip(' + lengthUnit + ')'"
              class="enlarged-input-small"
              ref="hip"
              v-model="hip"
              type="number"
              prepend-icon="mdi-tape-measure"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
        </v-row>
      </v-sheet>
      <v-row dense class="paper--text">
        <v-col cols="8">
          <span :class="textSizeSmall">Body Fat Percentage </span>
        </v-col>
        <v-spacer />
        <v-col right cols="4">
          <span :class="textSize + ' progressActive--text'"> {{ bfp }}</span>
        </v-col>
      </v-row>
      <v-row dense class="paper--text">
        <v-col cols="8">
          <span :class="textSizeSmall">Fat Mass </span>
        </v-col>
        <v-spacer />
        <v-col right cols="4">
          <span :class="textSize + ' progressActive--text'">
            {{ fm }} {{ unit(metric) }}</span
          >
        </v-col>
      </v-row>
      <v-row dense class="paper--text">
        <v-col cols="8">
          <span :class="textSizeSmall">Lean Mass </span>
        </v-col>
        <v-spacer />
        <v-col right cols="4">
          <span :class="textSize + ' progressActive--text'">
            {{ lm }} {{ unit(metric) }}</span
          >
        </v-col>
      </v-row>

      <v-card-subtitle :class="textSizeXSmall + ' silver--text px-0'">
        Enter your ideal "Body Fat Percentage," and we will calculate how much
        fat mass you will need to lose/gain to achieve your goal, as well as the
        expected caloric deficit/surplus. We would also calculate the number of
        days required to reach your goal using the recommended 500kcal/day
        deficit/excess or the more aggressive 750kcal/day.
      </v-card-subtitle>
      <v-row class="paper--text">
        <v-col cols="8">
          <span :class="textSizeSmall">Ideal Body Fat Percentage </span>
        </v-col>
        <v-spacer />
        <v-col left cols="4" md="2">
          <v-text-field
            dark
            label="Ideal BFP"
            class="enlarged-input-small"
            ref="ideal"
            v-model="ideal"
            type="number"
            append-icon="mdi-file-percent"
            tabindex="7"
            @input="calculateIdeal"
            @focus="$event.target.select()"
          />
        </v-col>
      </v-row>
      <template v-if="ideal > 0">
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Fat to {{ bfpAction }} to achieve desired BFP
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ fatToLose }} {{ unit(metric) }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Extra calories to {{ caloriesAction }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyNumber(caloriesToLose) }} kcal
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Days to goal @ 500kcal/day
            {{ caloriesAction == 'burn' ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal500 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal500) }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Days to goal @ 750kcal/day
            {{ caloriesAction == 'burn' ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal750 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 silver--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal750) }}
          </span>
        </v-card-actions>
      </template>
      <v-simple-table class="transparent paper--text" v-if="bfp > 0" flat>
        <thead>
          <tr>
            <th class="text-left title"></th>
            <th class="text-left title progressActive--text">You</th>
            <th class="text-left title paper--text">Range</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="mySex == 'Male'">
            <tr v-for="bfprange in bfprangesmen" :key="bfprange.name">
              <td>{{ bfprange.name }}</td>
              <td>
                <span v-if="bfp < bfprange.max && bfp > bfprange.min"
                  ><v-icon small :color="bfprange.color"
                    >mdi-checkbox-blank-circle</v-icon
                  ></span
                >
              </td>
              <td>{{ bfprange.range }}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="bfprange in bfprangeswomen" :key="bfprange.name">
              <td>{{ bfprange.name }}</td>
              <td>
                <span v-if="bfp < bfprange.max && bfp > bfprange.min">
                  <v-icon :color="bfprange.color">
                    mdi-checkbox-blank-circle
                  </v-icon>
                </span>
              </td>
              <td>{{ bfprange.range }}</td>
              <td>{{ bfprange.details }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>

      <v-row class="silver--text">
        <v-col cols="12">
          <h6 class="paper--text">What is BFP?</h6>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            BFP or Body Fat Percentage is the mass of fat divided by total body
            mass, multiplied by 100. There are two types of body fat, essential
            body fat, and storage body fat. As the name suggests, essential body
            fat is body fat that your body needs to function correctly. Women
            have higher essential body fat needs than men. Storage body fat is
            found in the adipose tissue and is the fat that people target when
            they are trying to trim down.
          </v-card-subtitle>
          <h6 class="paper--text">BFP vs BMI</h6>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            Your BMI is your Body Mass Index, and it is not a measure of your
            body fat. While BMI can tell you if you are underweight, of normal
            weight or overweight, it is agnostic of your body composition.
            That's why, based on BMI alone, muscular people will show as
            overweight. BFP, on the other hand, is a measure dedicated to
            finding out how much body fat a person has, as a percentage of the
            total body mass.
          </v-card-subtitle>
          <h6 class="paper--text mb-2">
            Methods of Measuring BFP
          </h6>
          <div class="body-1 paper--text">1. Tape Measure</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            The simplest method of measuring body fat involves taking a few
            measurements using a flexible tape measure and then using a
            calculator like the one on this page. It is a good idea to take each
            measurement two or three times and then calculate the average for a
            more accurate estimate.
          </v-card-subtitle>
          <div class="body-1 paper--text">2. Calipers</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            Calipers are a tool used to measure body fat using the skin-fold
            test method. Jackson and Pollock, in the '80s, developed the most
            common technique that requires a simple three site measurement,
            which is widely used today. A slower but more accurate method
            requires a seven site measurement. If you have a gym membership,
            chances are this assessment can be done free of charge by qualified
            gym personnel as a perk. You can also buy a set of calipers on the
            internet for as low as $7.
            <div class="py-3">
              If done correctly, this method is reasonably accurate, with a +/-
              3% error rate.
            </div>
          </v-card-subtitle>
          <div class="body-1 paper--text">3. Body Fat Scale</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            Body fat scales rely on a technology called bioelectrical impedance
            analysis (BIA). The scale uses an electrical current that passes
            through your body when you step on the scale to estimate your body
            composition. The calculations rely on the fact that fat, muscle
            tissue and water conduct electricity differently, with fat being
            less conductive than muscle and water.
            <div class="py-3">
              Body fat scales can be reliably unreliable. While some scales are
              more accurate than others, and technology is always improving, the
              results can widely vary when compared with other ways of
              measurement. There are quite a few variables that can affect the
              results, such as your hydration level, when you last ate, when you
              last exercised, etc.
            </div>
          </v-card-subtitle>
          <div class="body-1 paper--text">4. Hydrostatic Weight</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            The "Hydrostatic Weight" method uses Hydrodensitometry, which is a
            method of measuring density while submerged in water. Based on the
            volume of the water displaced and the buoyant force, your body fat
            percentage is determined. This method is highly accurate and is
            considered the
            <span class="progressActive--text"> gold standard</span> for
            measuring body fat percentage.
            <div class="py-3">
              The accuracy is within +/- 1%of body fat, and it's much more
              accurate than any at-home methods, like tape-measure, skinfold
              (calipers), and bioelectrical impedance.
            </div>
          </v-card-subtitle>
          <div class="body-1 paper--text">5. Air Displacement</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            Air displacement or plethysmography uses an egg-shaped chamber ( BOD
            POD) that encloses your body to determine your body density and then
            compute body fat percentage (BFP).
            <div class="py-3">
              The BOD POD is very accurate, and it matches the accuracy of
              underwater measurement at +/-1 % of body fat.
            </div>
          </v-card-subtitle>
          <div class="body-1 paper--text">6. DEXA Scan</div>
          <v-card-subtitle class=" px-0 pb-3 pt-0">
            A DEXA (dual-energy x-ray absorptiometry) scan is the most accurate
            way of measuring your Body Fat Percentage (BFP). A DEXA scan is much
            more than a way of measuring body fat thought, as it provides a
            comprehensive and accurate body composition report, detailing muscle
            mass, bone mass, water mass, etc.
            <div class="py-3">
              If you have the chance of doing a DEXA scan in your area and you
              can afford it (it can range between $75 and $150), definitely do
              so, it provides you with a very good way of tracking and
              understanding your body composition.
            </div>
          </v-card-subtitle>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
import bfprangesmen from '@/json/bfprangesmen.json'
import bfprangeswomen from '@/json/bfprangeswomen.json'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    mySex: '',
    male: false,
    female: false,
    weight: 0,
    weightLbs: 0,
    heightMetric: 0,
    heightInches: 0,
    feet: 0,
    inches: 0,
    waist: 0,
    neck: 0,
    hip: 0,
    waistInches: 0,
    neckInches: 0,
    hipInches: 0,
    cm: 0,
    bmi: 0,
    bfp: 0,
    age: 0,
    ideal: 0,
    showGender: true,
    fatToLose: 0,
    caloriesToLose: 0,
    daysToGoal500: 0,
    daysToGoal750: 0,
    dateToGoal500: null,
    dateToGoal750: null,
    bfpAction: '',
    caloriesAction: '',
    bfprangeswomen: bfprangeswomen,
    bfprangesmen: bfprangesmen,
    bfpranges: [],
    measureList: [],
    measurements: [],
    basicMeasures: null,
    toInches: 0.393701,
    toCm: 2.54,
    toLbs: 2.20462
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mySex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.weight.focus())
    if (this.loggedIn) this.loadData()
  },
  computed: {
    lengthUnit() {
      return this.metric ? 'cm' : 'inches'
    },
    fm() {
      let fm = 0
      if (this.weightLbs == 0) return 0
      else {
        fm = (this.bfp * this.weight) / 100
        fm = Math.floor(fm * 100) / 100
        return fm
      }
    },
    lm() {
      let lm = 0
      if (this.weightLbs == 0 || this.fm == 0) return 0
      else {
        lm = this.weight - this.fm
        lm = Math.floor(lm * 100) / 100
        return lm
      }
    },
    ...appConfig
  },
  methods: {
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },

    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      let weightID = this.getMeasureId('Weight')
      let heightID = this.getMeasureId('Height')
      let waistID = this.getMeasureId('Waist')
      let hipID = this.getMeasureId('Hip')
      let neckID = this.getMeasureId('Neck')
      let BFPID = this.getMeasureId('BFP')

      this.measurements.push({
        measurementid: BFPID,
        imperial: this.bfp,
        metric: this.bfp,
        measured: timestamp,
        is_parent: true
      })

      this.measurements.push({
        measurementid: weightID,
        imperial: this.weightLbs,
        metric: this.weightLbs / this.toLbs,
        measured: timestamp,
        is_parent: false
      })
      this.measurements.push({
        measurementid: heightID,
        imperial: this.heightInches,
        metric: this.heightInches * this.toCm,
        measured: timestamp,
        is_parent: false
      })

      this.measurements.push({
        measurementid: waistID,
        imperial: this.waistInches,
        metric: this.waistInches * this.toCm,
        measured: timestamp,
        is_parent: false
      })

      this.measurements.push({
        measurementid: hipID,
        imperial: neckID,
        metric: this.neckInches * this.toCm,
        measured: timestamp,
        is_parent: false
      })

      if (this.hip > 0)
        this.measurements.push({
          measurementid: hipID,
          imperial: this.hipInches,
          metric: this.hipInches * this.toCm,
          measured: timestamp,
          is_parent: false
        })
    },
    launchCalculator(url) {
      this.$router.push({
        path: url
      })
    },
    reset() {
      this.weight = 0
      this.age = 0
      this.neck = 0
      this.waist = 0
      this.hip = 0
      this.inches = 0
      this.feet = 0
      this.cm = 0
      this.bfp = 0
      this.ideal = 0
      this.bfpranges = []
      this.$nextTick(() => this.$refs.weight.focus())
    },
    switchMe() {
      this.reset()
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculateIdeal() {
      if (this.bfp <= 0 || this.ideal <= 0) {
        this.fatToLose = 0
        this.caloriesToLose = 0
        return
      } else {
        this.fatToLose = (Number(this.weight) * (this.bfp - this.ideal)) / 100
        if (this.metric) {
          this.fatToLose = this.fatToLose * 2.20462
        }
        this.fatToLose = this.twoDigits(this.fatToLose)
        this.caloriesToLose = Math.floor(3500 * this.fatToLose)

        if (this.fatToLose < 0) {
          this.bfpAction = 'gain'
          this.caloriesAction = 'consume'
        } else {
          this.bfpAction = 'lose'
          this.caloriesAction = 'burn'
        }
        this.daysToGoal500 = Math.floor(this.caloriesToLose / 500)
        this.daysToGoal750 = Math.floor(this.caloriesToLose / 750)
        this.dateToGoal500 = new Date()
        this.dateToGoal500.setDate(
          this.dateToGoal500.getDate() + this.daysToGoal500
        )
        this.dateToGoal750 = new Date()
        this.dateToGoal750.setDate(
          this.dateToGoal750.getDate() + this.daysToGoal750
        )
      }
    },
    outOfRange() {
      return (
        this.weight == 0 ||
        this.waist == 0 ||
        this.neck == 0 ||
        this.age == 0 ||
        (this.mySex != 'Male' && this.mySex != 'Female') ||
        (this.mySex == 'Female' && this.hip == 0)
      )
    },
    inImperial() {
      this.waistInches = this.metric
        ? Number(this.waist * this.toInches)
        : Number(this.waist)
      this.neckInches = this.metric
        ? Number(this.neck * this.toInches)
        : Number(this.neck)
      this.hipInches = this.metric
        ? Number(this.hip * this.toInches)
        : Number(this.hip)
      this.weightLbs = this.metric
        ? Number(this.weight * this.toLbs)
        : Number(this.weight)
      this.heightInches = this.metric
        ? Number(this.cm * this.toInches)
        : Number(this.feet) * 12 + Number(this.inches)
    },
    calculate() {
      this.inImperial()
      if (this.metric) {
        if (this.outOfRange() || this.cm < 30) {
          this.bfp = 0
          return
        }
      } else {
        if (this.outOfRange() || this.feet == 0) {
          this.bfp = 0
          return
        }
      }
      if (this.mySex == 'Male') {
        this.bfpranges = this.bfprangesmen
        this.bfp =
          86.01 * Math.log10(this.waistInches - this.neckInches) -
          70.041 * Math.log10(this.heightInches) +
          36.76
      } else if (this.mySex == 'Female') {
        if (this.hipInches < 15) {
          this.bfp = 0
          return
        }
        this.bfpranges = this.bfprangeswomen
        var added = this.waistInches + this.hipInches - this.neckInches
        var factor1 = 163.205 * Math.log10(added)
        var factor2 = 97.684 * Math.log10(this.heightInches)
        var factor3 = 78.387
        this.bfp = factor1 - factor2 - factor3
      } else this.bfp = 0

      this.bfp = Math.floor(this.bfp * 100) / 100
      if (this.bfp < 0) this.bfp = 0
      this.calculateIdeal()
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                if (el.name == 'Height') {
                  this.heightInches = el.imperial
                  this.heightCm = el.metric
                  this.feet = Math.floor(this.heightInches / 12)
                  this.inches = Math.round(this.heightInches % 12)
                }
                if (el.name == 'Waist') {
                  this.waist = this.metric ? el.metric : el.imperial
                }
                if (el.name == 'Neck') {
                  this.neck = this.metric ? el.metric : el.imperial
                }
                if (el.name == 'Hip') {
                  this.hip = this.metric ? el.metric : el.imperial
                }
                this.calculate()
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.bfp > 0) return
      this.setMeasures()

      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.mySex = ''
            this.measurements = {}
            this.toast('Measurements were succesfully saved')
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
