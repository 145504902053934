<template>
  <v-container>
    <BaseScreenHeader
      title="Body Composition"
      width="90%"
      screenInfo="CLC-008"
    />
    <v-card-subtitle :class="textSizeXSmall + ' py-0 mt-5 silver--text'">
      Use this calculator to better plan/estimate your weight loss/gain. See
      projections regarding your journey to your ideal body composition. Select
      your mysex, enter your current weight and "Body Fat Percentage" (BFP) and
      then your ideal body fat percentage. We will do the rest. If you don't
      know your "Body Fat Percentage" use our
      <a
        href="/calculators/bfp"
        class="progressActive--text"
        title="BFP(Body Fat Percentage Calculator"
      >
        BFP Calculator
      </a>
      to find it out.
    </v-card-subtitle>
    <v-card-subtitle :class="textSizeXSmall + ' silver--text'">
      We will calculate how much fat mass you need to lose/gain to achieve your
      goal as well as the needed caloric deficit/surplus. We would also
      calculate the number of days to reach your goal using the recommended
      500kcal/day deficit/excess or the more aggressive 750kcal/day.
    </v-card-subtitle>
    <v-sheet class="charcoal" :syle="charcoalTileMenu">
      <BaseLoginInvite />
      <v-card-actions class="pa-0">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            color="paper"
            class="mr-n2"
            dark
            :disabled="!weight > 0 || !bfp > 0 || !ideal > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>
      <v-sheet rounded color="transparent px-2" :style="charcoalTileMenu">
        <v-row dense class="px-0">
          <v-col v-if="showGender" cols="6" md="4">
            <v-radio-group dark v-model="mysex" ref="mysex" row @change="reset">
              <v-radio label="Female" value="Female"></v-radio>
              <v-radio label="Male" value="Male"></v-radio>
            </v-radio-group>
          </v-col>
          <v-spacer />
          <template v-if="mysex == 'Female' || mysex == 'Male'">
            <v-col cols="6" md="2">
              <v-text-field
                dark
                :label="'Weight(' + unit(metric) + ')'"
                class="enlarged-input-small"
                ref="weight"
                v-model="weight"
                type="number"
                prepend-icon="mdi-weight"
                tabindex="2"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col left cols="6" md="2">
              <v-text-field
                dark
                label="Body Fat %"
                class="enlarged-input-small"
                ref="bfp"
                v-model="bfp"
                type="number"
                prepend-icon="mdi-file-percent"
                tabindex="7"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col left cols="6" md="2">
              <v-text-field
                dark
                label="Ideal Body Fat %"
                class="enlarged-input-small"
                ref="ideal"
                v-model="ideal"
                type="number"
                prepend-icon="mdi-file-percent"
                tabindex="7"
                @input="calculate"
                @focus="$event.target.select()"
              />
            </v-col>
          </template>
        </v-row>
      </v-sheet>
      <v-divider class="py-2 mt-2" />
      <v-card-actions class="pa-0 mb-2 paper--text">
        <span :class="textSizeXSmall">
          Body Fat Percentage
        </span>
        <v-spacer />
        <span :class="textSizeSmall + ' progressActive--text'">
          {{ bfp }}
        </span>
      </v-card-actions>
      <v-card-actions class="pa-0 mb-2 paper--text">
        <span :class="textSizeXSmall">
          Fat Mass
        </span>
        <v-spacer />
        <span :class="textSizeSmall + ' progressActive--text'">
          {{ fm }} {{ unit(metric) }}
        </span>
      </v-card-actions>
      <v-card-actions class="pa-0 mb-2 paper--text">
        <span :class="textSizeXSmall">
          Lean Mass
        </span>
        <v-spacer />
        <span :class="textSizeSmall + ' progressActive--text'">
          {{ lm }} {{ unit(metric) }}
        </span>
      </v-card-actions>
      <template v-if="ideal > 0">
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Fat to {{ bfpAction }} to achieve desired BFP
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ fatToLose }} {{ unit(metric) }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Extra calories to {{ caloriesAction }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyNumber(caloriesToLose) }} kcal
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Days to goal @ 500kcal/day
            {{ caloriesAction == 'burn' ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal500 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal500) }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Days to goal @ 750kcal/day
            {{ caloriesAction == 'burn' ? 'deficit' : 'excess' }}
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ daysToGoal750 }}
          </span>
        </v-card-actions>
        <v-card-actions class="pa-0 mb-2 paper--text">
          <span :class="textSizeXSmall">
            Goal Achieved Date
          </span>
          <v-spacer />
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ prettyDate(dateToGoal750) }}
          </span>
        </v-card-actions>
      </template>
      <v-simple-table class="transparent paper--text mt-2" v-if="bfp > 0" flat>
        <thead>
          <tr>
            <th class="text-left title"></th>
            <th class="text-left title progressActive--text">You</th>
            <th class="text-left title paper--text">Range</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="mysex == 'Male'">
            <tr v-for="bfprange in bfprangesmen" :key="bfprange.name">
              <td>{{ bfprange.name }}</td>
              <td>
                <span v-if="bfp < bfprange.max && bfp >= bfprange.min"
                  ><v-icon small :color="bfprange.color"
                    >mdi-checkbox-blank-circle</v-icon
                  ></span
                >
              </td>
              <td>{{ bfprange.range }}</td>
            </tr>
          </template>
          <template v-else>
            <tr v-for="bfprange in bfprangeswomen" :key="bfprange.name">
              <td>{{ bfprange.name }}</td>
              <td>
                <span v-if="bfp < bfprange.max && bfp >= bfprange.min"
                  ><v-icon :color="bfprange.color"
                    >mdi-checkbox-blank-circle</v-icon
                  ></span
                >
              </td>
              <td>{{ bfprange.range }}</td>
            </tr>
          </template>
        </tbody>
      </v-simple-table>
    </v-sheet>
  </v-container>
</template>

<script>
import { appConfig } from '@/store/helpers.js'
import bfprangesmen from '@/json/bfprangesmen.json'
import bfprangeswomen from '@/json/bfprangeswomen.json'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    mysex: '',
    weight: 0,
    weightLbs: 0,
    bfp: 0,
    ideal: 0,
    showGender: true,
    fatToLose: 0,
    caloriesToLose: 0,
    daysToGoal500: 0,
    daysToGoal750: 0,
    dateToGoal500: null,
    dateToGoal750: null,
    bfpAction: '',
    caloriesAction: '',
    bfprangeswomen: bfprangeswomen,
    bfprangesmen: bfprangesmen,
    basicMeasures: null,
    toLbs: 2.20462,
    bfpranges: [],
    measureList: [],
    measurements: [],
    byWeight: 0
  }),

  validations: {},
  computed: {
    lengthUnit() {
      return this.metric ? 'cm' : 'inches'
    },
    fm() {
      let fm = 0
      if (this.weightLbs == 0) return 0
      else {
        fm = (this.bfp * this.weight) / 100
        fm = Math.floor(fm * 100) / 100
        return fm
      }
    },
    lm() {
      let lm = 0
      if (this.weightLbs == 0 || this.fm == 0) return 0
      else {
        lm = this.weight - this.fm
        lm = Math.floor(lm * 100) / 100
        return lm
      }
    },
    ...appConfig
  },
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mysex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    if (this.loggedIn) this.loadData()
  },
  methods: {
    twoDigits(what) {
      return Math.floor(what * 100) / 100
    },
    getMeasureId(what) {
      let id = 0
      this.measureList.forEach(el => {
        if (el.name.toLowerCase() == what) id = el.id
      })
      return id
    },
    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      this.measurements.push({
        measurementid: this.getMeasureId('weight'),
        imperial: this.twoDigits(this.weightLbs),
        metric: this.twoDigits(this.weightLbs / this.toLbs),
        measured: timestamp
      })
      this.measurements.push({
        measurementid: this.getMeasureId('bfp'),
        imperial: this.bfp,
        metric: this.bfp,
        measured: timestamp
      })
    },
    launchCalculator(url) {
      this.$router.push({
        path: url
      })
    },
    reset() {
      this.weight = 0
      this.bfp = 0
      this.ideal = 0
      this.bfpranges = []
      this.$nextTick(() => this.$refs.weight.focus())
    },
    switchMe() {
      this.reset()
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculateIdeal() {
      if (this.metric) this.weightLbs = this.weight * this.toLbs
      else this.weightLbs = this.weight

      if (this.bfp <= 0 || this.ideal <= 0) {
        this.fatToLose = 0
        this.caloriesToLose = 0
        return
      } else {
        this.fatToLose = (Number(this.weight) * (this.bfp - this.ideal)) / 100
        if (this.metric) {
          this.fatToLose = this.fatToLose * 2.20462
        }
        this.fatToLose = Math.floor(this.fatToLose * 100) / 100
        this.caloriesToLose = Math.floor(3500 * this.fatToLose)

        if (this.fatToLose < 0) {
          this.bfpAction = 'gain'
          this.caloriesAction = 'consume'
        } else {
          this.bfpAction = 'lose'
          this.caloriesAction = 'burn'
        }
        this.daysToGoal500 = Math.floor(this.caloriesToLose / 500)
        this.daysToGoal750 = Math.floor(this.caloriesToLose / 750)
        this.dateToGoal500 = new Date()
        this.dateToGoal500.setDate(
          this.dateToGoal500.getDate() + this.daysToGoal500
        )
        this.dateToGoal750 = new Date()
        this.dateToGoal750.setDate(
          this.dateToGoal750.getDate() + this.daysToGoal750
        )
      }
    },
    outOfRange() {
      return (
        this.weight == 0 || (this.mysex != 'Male' && this.mysex != 'Female')
      )
    },
    inImperial() {
      this.weightLbs = this.metric
        ? Number(this.weight * this.toLbs)
        : Number(this.weight)
    },
    calculate() {
      this.inImperial()
      if (this.metric) {
        if (this.outOfRange()) {
          this.bfp = 0
          return
        }
      } else {
        if (this.outOfRange()) {
          this.bfp = 0
          return
        }
      }
      if (this.mysex == 'Male') {
        this.bfpranges = this.bfprangesmen
      } else if (this.mysex == 'Female') {
        this.bfpranges = this.bfprangeswomen
      } else this.bfp = 0

      this.bfp = Math.floor(this.bfp * 100) / 100
      if (this.bfp < 0) this.bfp = 0
      this.calculateIdeal()
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                if (el.name == 'BFP') {
                  this.bfp = el.imperial
                }
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.bfp > 0) return
      this.setMeasures()

      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.reset()
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
