<template>
  <v-container>
    <BaseScreenHeader
      title="Protein Intake"
      width="90%"
      screenInfo="CLC-009"
    />

    <v-card-subtitle :class="textSizeXSmall + ' silver--text mt-5 py-0 px-0'">
      This calculator allows you to calculate how much protein you need based on
      your weight, lifestyle, goals, etc. Please enter your weight and make the
      appropriate selection for your scenario and we will do the rest.
    </v-card-subtitle>
    <v-sheet class="charcoal px-0">
      <BaseLoginInvite />
      <v-card-actions class="px-0 pb-0">
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            class="charcoal paper--text mr-n2"
            dark
            :disabled="!weight > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>
      <v-card-actions class="py-0 px-2">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-switch
          dark
          v-model="overweight"
          :label="'Overweight?'"
          @change="overweightSwitch"
          color="progressActive"
        />
      </v-card-actions>

      <v-sheet rounded color="transparent mt-n2 px-2" :style="charcoalTileMenu">
        <v-row dense>
          <v-col v-if="!overweight" cols="12" md="3">
            <v-radio-group dark v-model="mysex" row @change="restart">
              <v-radio :label="`Female`" value="Female" />
              <v-radio :label="`Male`" value="Male" />
            </v-radio-group>
          </v-col>
          <v-spacer />
          <v-col cols="12" md="6">
            <v-row v-if="mysex != '' || overweight" class="px-2">
              <v-col cols="6" md="6">
                <v-text-field
                  color="success"
                  dark
                  :label="'Weight(' + unit(metric) + ')'"
                  class="enlarged"
                  ref="weight"
                  v-model="weight"
                  type="number"
                  prepend-inner-icon="mdi-weight"
                  @input="calculate"
                  @focus="$event.target.select()"
                />
              </v-col>
              <template v-if="!overweight">
                <v-col v-if="mysex == 'Female'" cols="6">
                  <v-select
                    dark
                    color="success"
                    v-model="special"
                    return-object
                    prepend-inner-icon="mdi-baby-carriage"
                    :items="activityConditions"
                    label="Special Conditions"
                    outlined
                    item-text="text"
                    item-value="value"
                    @change="calculate"
                  />
                </v-col>
                <v-col
                  cols="12"
                  v-if="special.text == 'None' || mysex == 'Male'"
                >
                  <v-select
                    dark
                    color="success"
                    v-model="activity"
                    prepend-inner-icon="mdi-bike-fast"
                    :items="activityLevels"
                    label="Activity Level"
                    outlined
                    @change="sedentarySwitch"
                  />
                </v-col>
                <v-col v-if="activity == 'Active'" cols="12">
                  <v-select
                    dark
                    color="success"
                    return-object
                    v-model="goal"
                    prepend-inner-icon="mdi-check-circle"
                    :items="goals"
                    label="Goal"
                    outlined
                    @change="calculate"
                  />
                </v-col>
              </template>
            </v-row>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card-actions v-if="weight > 0" class="paper--text pa-0 my-4">
        <span :class="textSizeSmall">
          Daily Protein Intake
        </span>
        <v-spacer />
        <span :class="textSizeSmall + ' progressActive--text'">
          <template
            v-if="
              (proteinMin == 0 && proteinMax == 0) ||
                (proteinMin > 0 && proteinMax > 0)
            "
            >{{ proteinMin }} to {{ proteinMax }} grams/day</template
          >
          <template v-else> More than: {{ proteinMin }} grams/day </template>
        </span>
      </v-card-actions>
      <v-row dense class="silver--text">
        <v-col cols="12" md="6">
          <h6 class="paper--text">What is Protein?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Proteins are the primary building blocks for most lifeforms on
            Earth. From a nutritional perspective, proteins are one of the
            primary macronutrients the human body needs to survive. It is a
            macronutrient because, just like fats and carbohydrates, we use it
            to fuel our bodies and we consume a rather large amount every day.
          </v-card-subtitle>
          <h6 class="paper--text">
            How Much Protein Do You Need Daily?
          </h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            The recommended dietary allowance (RDA) set by the U.S. government
            starts at around 0.8 grams/kg of body weight for sedentary adults 18
            and over, which translates to roughly 0.36 grams per pound of body
            weight. The RDA for children is almost double, at 1.5 grams of
            protein per kilogram of body weight. For an average 160lbs person,
            this translates to 58 grams of protein daily. Now please note, the
            RDA is not an ideal intake but the minimum amount needed for your
            body to function properly. In reality, your daily protein would
            probably need to be much higher, even double the RDA, depending on
            your goal, activity level, etc
          </v-card-subtitle>
          <h6 class="paper--text">Do I Need to Supplement Protein?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            The average healthy diet normally provides enough protein for most
            people. However, if you exercise regularly and intend to build
            muscle or lose fat, you will benefit from supplementing your daily
            protein intake using either dietary protein or supplements.
          </v-card-subtitle>

          <h6 class="paper--text">How Is The Calculator Working?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pa-0'">
            Please see below for details regarding the values/scenarios handled
            by this calculator. Ranges are determined based on your total body
            weight.
            <p class="py-3">
              Remember that merely adding more protein to your diet will not, on
              its merit, change your body composition (increased muscle mass,
              reduce fat). A proper exercise regimen, including cardio and
              especially resistance training, must also be employed.
            </p>
          </v-card-subtitle>
          <v-card-title class="paper--text pt-0">
            If you are active
          </v-card-title>
          <v-card-subtitle class="body-2 pb-2">
            If you are active, of a healthy weight and wish you maintain your
            body composition, aim for 1.4–1.6 g/kg (0.64–0.73 g/lb). If you are
            trying to keep your weight but also improve your body composition,
            (add muscle, reduce fat), you may benefit from the higher end of the
            range.
          </v-card-subtitle>
          <v-card-subtitle class="body-2 pb-2">
            If you are active, of healthy weight, and wish to build muscle, aim
            for 1.4–2.4 g/kg (0.64–1.09 g/lb). If you are an experienced lifter
            trying to add muscle mass, increasing your intake to up to 3.3 g/kg
            (1.50 g/lb) might help to minimize fat gain.
          </v-card-subtitle>
          <v-card-subtitle class="body-2 pb-2">
            If you are active, of healthy weight, and wish to lose fat, aim for
            1.8–2.7 g/kg (0.82–1.23 g/lb). As your body composition changes and
            you become leaner or as you increase your caloric deficit, try
            moving your intake toward the higher end of this range.
          </v-card-subtitle>
          <v-card-title class="paper--text pt-0"
            >If you are overweight</v-card-title
          >
          <v-card-subtitle class="body-2 pb-2">
            If you are overweight or obese, aim for 1.2–1.5 g/kg (0.54–0.68
            g/lb).
          </v-card-subtitle>
          <v-card-title class="paper--text pt-0"
            >If you are sedentary</v-card-title
          >
          <v-card-subtitle class="body-2 pb-2">
            If you are overweight or obese, aim for at least 1.2 g/kg (0.54
            g/lb).
          </v-card-subtitle>
          <v-card-title class="paper--text pt-0"
            >If you are pregnant</v-card-title
          >
          <v-card-subtitle class="body-2 pb-2">
            If you are pregnant, aim for 1.66–1.77 g/kg (0.75–0.80 g/lb).
          </v-card-subtitle>
          <v-card-title class="paper--text pt-0"
            >If you are lactating</v-card-title
          >
          <v-card-subtitle class="body-2 pb-2">
            If you are pregnant, aim for at least 1.5 g/kg (0.68 g/lb).
          </v-card-subtitle>
        </v-col>
        <v-col cols="12" md="6">
          <a
            href="/pdf/infographics/HowMuchDailyProtein-bfit.fit.pdf"
            title="How much protein you need? Infographic"
            target="_blank"
          >
            <v-img
              :src="
                require('@/assets/infographics/HowMuchDailyProtein-bfit.fit.png')
              "
              alt="How much protein you need? Infographic"
              title="How much protein you need? Infographic"
              contain
            />
          </a>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import bmiRanges from '@/json/bmiranges.json'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    mysex: '',
    male: false,
    female: false,
    overweight: false,
    weight: 0,
    weightLbs: 0,
    weightKg: 0,
    proteinMin: 0,
    proteinMax: 0,
    rangeMin: 0,
    rangeMax: 0,

    activity: '',
    special: '',
    goal: '',
    showGender: true,
    bmiRanges: bmiRanges,
    measurements: [],
    measureList: [],
    activityLevels: ['Sedentary', 'Active'],
    activityConditions: [
      { text: 'Pregnant', value: '1.66 - 1.77 g/kg', min: '1.66', max: '1.77' },
      { text: 'Lactating', value: '> 1.5 g/kg', min: '1.5', max: null },
      { text: 'None', value: '', min: null, max: null }
    ],
    goals: [
      { text: 'Maintenance', value: '1.4 - 1.6 g/kg', min: '1.4', max: '1.6' },
      { text: 'Fat Loss', value: '1.8 - 2.7 g/kg', min: '1.8', max: '2.7' },
      {
        text: 'Muscle Building',
        value: '1.4 - 2.4 g/kg',
        min: '1.4',
        max: '2.4'
      }
    ],
    toLbs: 2.20462
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mysex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    if (this.loggedIn) this.loadData()
  },
  validations: {},
  computed: {
    ...appConfig
  },
  methods: {
    noDigits(what) {
      return Math.floor(what)
    },
    getMeasureId(what) {
      let id = 0
      this.measureList.forEach(el => {
        if (el.name.toLowerCase() == what) id = el.id
      })
      return id
    },
    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      this.measurements.push({
        measurementid: this.getMeasureId('weight'),
        imperial: this.weightLbs,
        metric: this.weightKg,
        measured: timestamp
      })
    },
    switchMe() {
      this.restart()
    },
    sedentarySwitch() {
      this.calculate()
    },
    overweightSwitch() {
      this.$nextTick(() => this.$refs.weight.focus())
      this.calculate()
    },
    reset() {
      this.mysex = ''
      this.restart()
      this.$nextTick(() => this.$refs.weight.focus())
    },
    restart() {
      this.weight = 0
      this.overweight = false
      this.goal = ''
      this.special = ''
      this.activity = ''
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculate() {
      if (this.weight == 0) {
        this.proteinMin = 0
        this.proteinMax = 0
        return
      }
      if (this.metric) {
        this.weightLbs = this.noDigits(this.weight * this.toLbs)
        this.weightKg = this.noDigits(this.weight)
      } else {
        this.weightLbs = this.noDigits(this.weight)
        this.weightKg = this.noDigits(this.weight / this.toLbs)
      }
      if (this.overweight) {
        this.rangeMin = 1.2
        this.rangeMax = 1.5
      } else if (
        this.special.text == 'Pregnant' ||
        this.special.text == 'Lactating'
      ) {
        this.rangeMin = this.special.min
        this.rangeMax = this.special.max
        if (this.special.text == 'Lactating') this.rangeMax = 0
      } else if (this.activity == 'Sedentary') {
        this.rangeMin = 1.2
        this.rangeMax = 0
      } else {
        if (this.activity == 'Active' && this.goal != '') {
          this.rangeMin = this.goal.min
          this.rangeMax = this.goal.max
        } else {
          this.rangeMin = 0
          this.rangeMax = 0
        }
      }
      this.proteinMin = this.noDigits(this.rangeMin * this.weightKg)
      if (this.rangeMax == null) this.proteinMax == 0
      else this.proteinMax = this.noDigits(this.rangeMax * this.weightKg)
      this.setMeasures()
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                if (el.name == 'BFP') {
                  this.bfp = el.imperial
                }
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.weight > 0) return
      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
