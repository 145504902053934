<template>
  <v-container>
    <BaseScreenHeader title="BMR & TDEE" width="90%" screenInfo="CLC-003" />

    <v-card-subtitle :class="textSizeXSmall + ' mt-5 py-0 silver--text'">
      <div class="title">BMR & TDEE Calculator</div>
      To calculate your BMR (Basal Metabolic Rate), please enter your sex, age,
      weight, height, and then select your activity level. The system will
      calculate both your BMR as well as your TDEE(Total Daily Energy
      Expenditure).
      <template v-if="loggedIn">
        Clicking <span class="progressActive--text">SAVE</span> below will also
        save the entered weight as your current weight as well as your
        calculated BMR & TDEE.</template
      >
    </v-card-subtitle>
    <v-sheet class="px-2 charcoal">
      <BaseLoginInvite class="pt-2" />
      <v-card-actions class="px-0 mr-n2">
        <v-spacer />
        <BaseActionButton
          v-if="loggedIn"
          v-bind="$attrs"
          text
          dark
          class="charcoal paper--text"
          :disabled="!bmr > 0"
          @clickedThis="saveMeasurement"
        />
      </v-card-actions>
      <v-row dense>
        <v-col cols="5" md="5">
          <v-switch
            dark
            v-model="metric"
            :label="'Metric?'"
            tabindex="0"
            @change="switchMe"
          ></v-switch>
        </v-col>
        <v-spacer />
        <v-col cols="7" md="4">
          <v-radio-group dark v-model="formula" row @change="calculate">
            <v-radio
              label="Miffin & St Jeor"
              value="Miffin & St Jeor"
              selected
            />
            <v-radio label="Harris-Benedict" value="Harris-Benedict" />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-sheet
        rounded
        color="transparent"
        class="px-2"
        :style="charcoalTileMenu"
      >
        <v-row class="px-2">
          <v-col cols="12" md="3">
            <v-radio-group
              dark
              v-model="mySex"
              row
              tabindex="1"
              @change="calculate"
            >
              <v-radio :label="`Female`" value="Female"></v-radio>
              <v-radio :label="`Male`" value="Male"></v-radio>
            </v-radio-group>
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              dark
              label="Age (Years)"
              class="enlarged-input-small"
              ref="age"
              v-model="age"
              type="number"
              prepend-inner-icon="mdi-clock"
              color="success"
              @input="calculate"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              dark
              :label="'Weight(' + unit(metric) + ')'"
              class="enlarged-input-small"
              ref="weight"
              v-model="weight"
              type="number"
              prepend-inner-icon="mdi-weight"
              color="success"
              @input="calculate"
            />
          </v-col>
          <template v-if="!metric">
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(feet)"
                class="enlarged-input-small"
                ref="feet"
                v-model="feet"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                color="success"
                @input="calculate"
              />
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(inches)"
                class="enlarged-input-small"
                ref="inches"
                v-model="inches"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                color="success"
                @input="calculate"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(cm)"
                class="enlarged-input-small"
                ref="cm"
                v-model="cm"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                color="success"
                @input="calculate"
              />
            </v-col>
          </template>
        </v-row>
        <v-row dense>
          <v-col cols="12" md="6">
            <v-select
              dark
              filled
              color="success"
              return-object
              v-model="coeficient"
              prepend-inner-icon="mdi-bike-fast"
              :items="activityLevels"
              label="Activity Level"
              item-text="text"
              item-value="coeficient.value"
              @change="calculate"
            />
          </v-col>
          <v-col cols="12" class="pb-3 pl-5 mt-n5">
            <span :class="textSizeXSmall"> {{ coeficient.details }}</span>
          </v-col>
        </v-row>
      </v-sheet>
      <v-divider class="mt-5" />
      <v-row dense class="paper--text">
        <v-col cols="6" md="7">
          <span :class="textSizeSmall">BMR</span>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="4">
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ bmr }} Kcalories/day</span
          >
        </v-col>
      </v-row>
      <v-row dense class="paper--text">
        <v-col cols="6" md="7">
          <span :class="textSizeSmall">
            TDEE
          </span>
        </v-col>
        <v-spacer />
        <v-col cols="6" md="4">
          <span :class="textSizeSmall + ' progressActive--text'">
            {{ tdee }} Kcalories/day</span
          >
        </v-col>
      </v-row>

      <v-row dense class="silver--text mt-4">
        <v-col cols="12" md="6">
          <h6 class="paper--text">What are BMR and TDEE?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Your BMR tells you how many calories your body burns when at rest.
            That is, how many calories your body needs to function if you were
            to lay in bed all day?
            <div class="py-3">
              TDEE uses your BMR as the starting point, and estimates how many
              calories you need during a day, adjusting using your level of
              activity, sex, and age.
            </div>
          </v-card-subtitle>
          <h6 class="paper--text">
            Why Do I need To Know My BMR and TDEE?
          </h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Knowing your BMR and TDEE are the first steps in taking control of
            your body composition. To understand what you need to do to change
            your body composition, you first need to know what your body needs
            (calorie-wise) to maintain, to stay where it is.
            <div class="py-3">
              Once you know your BMR and TDEE, you can then make adjustments in
              either your exercise regimen or diet to accomplish your goals. If
              you also track your food consumption (tracking the number of
              calories consumed each day), you can have a clear picture of your
              daily caloric excess or deficit. In the end, losing or gaining
              weight is as simple as controlling what goes in and what gets
              burned.
            </div>
          </v-card-subtitle>
          <h6 class="paper--text">Why Limit Daily Deficit/Excess?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Literature suggests keeping your daily deficit/excess of calories
            within the 500Kcal and 750Kcal range. It is more sustainable in the
            long term and doesn't negatively impact your body.
            <div class="py-3">
              Please
              <span class="progressActive--text">consult your doctor </span>
              before doing anything too extreme.
            </div>
          </v-card-subtitle>
          <h6 class="paper--text">Why Two Formulas?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Harris-Benedict Equation (HBE) was using developed predominantly
            people aged 15-74 of average weight and is an older formula. HBE is
            more likely to overestimate than underestimate.
            <div class="py-3">
              Mifflin-St Jeor is an improvement over HBE and is generally more
              accurate (by about 5%).
            </div>
            <v-divider class="py-2 mt-2" />
          </v-card-subtitle>
        </v-col>
        <v-col cols="12" md="6">
          <a
            href="/pdf/infographics/BMRAndTDEE-HowToUse-bfit.fit.pdf"
            title="BMR (Basal Metabolic Rate) & TDEE (Total Daily Energy Expenditure). Infographic"
            target="_blank"
          >
            <v-img
              :src="
                require('@/assets/infographics/BMRAndTDEE-HowToUse-bfit.fit.png')
              "
              alt="BMR (Basal Metabolic Rate) & TDEE (Total Daily Energy Expenditure). Infographic"
              title="BMR (Basal Metabolic Rate) & TDEE (Total Daily Energy Expenditure). Infographic"
              contain
            />
          </a>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>

<script>
import activityLevels from '@/json/activitylevels.json'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    age: 0,
    mySex: '',
    weight: 0,
    weightLbs: 0,
    weightKg: 0,
    heightInches: 0,
    feet: 0,
    inches: 0,
    cm: 0,
    bmr: 0,
    tdee: 0,
    formula: 'Miffin & St Jeor',
    coeficient: {},
    measurements: [],
    measureList: [],
    basicMeasures: null,
    activityLevels: activityLevels,
    toLbs: 2.20462
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mySex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.age.focus())
    this.coeficient.value = 1.2
    if (this.loggedIn) this.loadData()
  },
  computed: {
    ...appConfig
  },
  methods: {
    getMeasureId(what) {
      let id = 0
      this.measureList.forEach(el => {
        if (el.name.toLowerCase() == what) id = el.id
      })
      return id
    },
    switchMe() {
      this.weight = 0
      this.age = 0
      this.inches = 0
      this.feet = 0
      this.cm = 0
      this.$nextTick(() => this.$refs.age.focus())
    },
    calculate() {
      if (this.metric) {
        this.heightInches = Math.round(this.cm * 0.393701)
        this.heightCm = this.cm
        this.weightKg = this.weight
        this.weightLbs = this.weight * this.toLbs
        this.weightLbs = this.twoDigits(this.weightLbs)
      } else {
        this.heightInches = Number(this.feet) * 12 + Number(this.inches)
        this.heightCm = this.heightInches * 2.54
        this.weightLbs = this.twoDigits(this.weight)
        this.weightKg = this.twoDigits(this.weight / this.toLbs)
      }

      if (
        (this.mySex != 'Female' && !this.mySex == 'Male') ||
        !this.age > 0 ||
        !this.weight > 0 ||
        (this.metric && !this.cm > 0) ||
        (!this.metric && !this.feet > 0)
      ) {
        this.bmr = 0
        this.tdee = 0
        return
      } else {
        if (this.mySex == 'Female') {
          if (this.formula == 'Miffin & St Jeor') {
            this.bmr = Math.round(
              10 * Number(this.weightKg) +
                6.25 * Number(this.heightCm) -
                5 * Number(this.age) -
                161
            )
          } else {
            this.bmr = Math.round(
              655.1 +
                4.35 * Number(this.weightLbs) +
                4.7 * Number(this.heightInches) -
                4.7 * Number(this.age)
            )
          }
        } else {
          if (this.formula == 'Miffin & St Jeor') {
            this.bmr = Math.round(
              10 * Number(this.weightKg) +
                6.25 * Number(this.heightCm) -
                5 * Number(this.age) +
                5
            )
          } else {
            this.bmr = Math.round(
              66 +
                6.2 * Number(this.weightLbs) +
                12.7 * Number(this.heightInches) -
                6.76 * Number(this.age)
            )
          }
        }
        this.tdee = Math.round(Number(this.coeficient.value) * this.bmr)
        this.setMeasures()
      }
    },
    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      this.measurements.push({
        measurementid: this.getMeasureId('weight'),
        imperial: this.weightLbs,
        metric: this.weightKg,
        measured: timestamp
      })
      this.measurements.push({
        measurementid: this.getMeasureId('bmr'),
        imperial: this.bmr,
        metric: this.bmr,
        measured: timestamp
      })
      this.measurements.push({
        measurementid: this.getMeasureId('tdee'),
        imperial: this.tdee,
        metric: this.tdee,
        measured: timestamp
      })
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age

              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                if (el.name == 'Height') {
                  this.heightInches = el.imperial
                  this.heightCm = el.metric
                  this.feet = Math.floor(this.heightInches / 12)
                  this.inches = Math.round(this.heightInches % 12)
                }
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.weight > 0) return
      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
