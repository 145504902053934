<template>
  <v-container>
    <BaseScreenHeader
      title="Calculators"
      width="90%"
      screenInfo="CLC-000"
      :showBack="true"
    />

    <v-card-actions class="pb-0 mt-2">
      <v-spacer />
      <v-switch
        dark
        v-model="showHelpText"
        color="accent"
        class="ma-0 px-0 pb-0"
        label="Show Help Text?"
      />
    </v-card-actions>
    <v-card-subtitle
      v-if="showHelpText"
      :class="textSizeXSmall + ' silver--text py-0'"
    >
      Our fitness calculators are designed to help you track, measure, and make
      informed decisions regarding your fitness plan and goals. Please note that
      the provided tools are for informational purposes only. You should
      <span class="progressActive--text">consult your doctor </span> before
      making any decisions that may impact your health.
    </v-card-subtitle>
    <v-sheet class="charcoal">
      <v-card-text class="pa-0">
        <v-card-actions class="px-0">
          <BaseSearch
            dark
            :items="calculators"
            label="Calculators"
            @search_results="setResults"
            :searchIn="['name', 'type']"
          />
        </v-card-actions>
        <v-row justify="center" class="mt-2 px-0 mx-0" dense>
          <v-col
            v-for="calculator in currentCalculators"
            :key="calculator.name"
            cols="12"
            justity="center"
            class="pt-2 mb-2 mx-auto"
            :style="charcoalTileMenu"
          >
            <v-card
              flat
              tile
              class="transparent mb-2 flex-grow-0"
              :title="'Use ' + calculator.name"
              hover
              href
              :to="calculator.url"
            >
              <v-sheet class="transparent paper--text">
                <v-card-title :class="textSizeSmall + ' px-1 pt-1'">
                  {{ calculator.name }} <v-spacer />
                  <v-icon
                    large
                    :title="'Calculator Type: ' + calculator.type"
                    :color="calculator.color"
                  >
                    mdi-checkbox-blank
                  </v-icon>
                </v-card-title>
                <v-card-subtitle
                  :class="textSizeXSmall + ' silver--text grow px-1 pt-2 pb-0'"
                >
                  <span v-html="calculator.details"> </span>
                </v-card-subtitle>
              </v-sheet>

              <v-card-actions class="pa-0">
                <v-spacer />
                <v-btn
                  :title="'Use ' + calculator.name"
                  fab
                  medium
                  color="transparent"
                  elevation="0"
                >
                  <v-icon large class="paper--text">
                    mdi-gesture-tap
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-sheet>
  </v-container>
</template>

<script>
import calculators from '@/json/calculators.json'
import util from '@/mixins/util.js'
export default {
  mixins: [util],
  name: 'AllCalculators',
  data: () => ({
    calculators: calculators,
    showHelpText: false,
    currentCalculators: [],
    loadedCalculators: []
  }),
  beforeMount() {
    this.currentCalculators = this.calculators
  },
  methods: {
    setResults(value) {
      this.currentCalculators = value
    },

    launchCalculator(url) {
      this.$router.push({
        path: url
      })
    }
  }
}
</script>
<style scoped>
.flexcard {
  display: flex;
  flex-direction: column;
}
</style>
