<template>
  <v-container>
    <BaseScreenHeader title="BMI Calculator" width="90%" screenInfo="CLC-005" />

    <v-card-subtitle :class="textSizeXSmall + ' mt-5 pt-0 px-2 silver--text'">
      To calculate your BMI(Body Mass Index), please enter your weight, height
      and sex. The system will calculate both your BMI (Body Mass Index) and
      display the appropriate healthy BMI range.
    </v-card-subtitle>
    <v-card-subtitle :class="textSizeXSmall + ' px-2 silver--text pt-0'">
      If you enter your age, the system will also calculate your estimated Body
      Fat Percentage.
      <template v-if="loggedIn">
        Clicking <span class="progressActive--text">SAVE</span> below will also
        save the entered weight as today's weight as well as your calculated
        BMI.
      </template>
    </v-card-subtitle>
    <v-sheet class="charcoal">
      <v-card-actions class="pr-0 mr-n2">
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
        />
        <v-spacer />
        <v-layout v-if="loggedIn" justify-end>
          <BaseActionButton
            v-if="loggedIn"
            :large="!isPhone"
            v-bind="$attrs"
            text
            color="paper"
            dark
            :disabled="!bmi > 0"
            @clickedThis="saveMeasurement"
          />
        </v-layout>
        <v-layout v-else row justify-end>
          <BaseLoginInvite text="To Save" />
        </v-layout>
      </v-card-actions>
      <v-sheet rounded class="transparent px-2 mx-2" :style="charcoalTileMenu">
        <v-row class="px-2">
          <v-col cols="6" md="2">
            <v-text-field
              dark
              label="Age (Years)"
              class="enlarged-input-small"
              ref="age"
              counter="2"
              v-model="age"
              type="number"
              prepend-inner-icon="mdi-clock"
              @input="calculate"
              color="success"
              @focus="$event.target.select()"
            />
          </v-col>
          <v-col cols="6" md="2">
            <v-text-field
              dark
              :label="'Weight(' + unit(metric) + ')'"
              class="enlarged-input-small"
              counter="5"
              ref="weight"
              v-model="weight"
              type="number"
              prepend-inner-icon="mdi-weight"
              @input="calculate"
              color="success"
              @focus="$event.target.select()"
            />
          </v-col>
          <template v-if="!metric">
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(feet)"
                class="enlarged-input-small"
                ref="feet"
                counter="1"
                v-model="feet"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                @input="calculate"
                color="success"
                @focus="$event.target.select()"
              />
            </v-col>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(inches)"
                class="enlarged-input-small"
                ref="inches"
                counter="1"
                v-model="inches"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                @input="calculate"
                color="success"
                @focus="$event.target.select()"
              />
            </v-col>
          </template>
          <template v-else>
            <v-col cols="6" md="2">
              <v-text-field
                dark
                label="Height(cm)"
                class="enlarged-input-small"
                ref="cm"
                v-model="cm"
                type="number"
                prepend-inner-icon="mdi-tape-measure"
                @input="calculate"
                color="success"
              />
            </v-col>
          </template>
          <v-spacer v-if="!showGender" />
          <v-col v-if="showGender" cols="12" md="3">
            <v-radio-group dark v-model="mySex" row @change="calculate">
              <v-radio :label="`Female`" value="Female"></v-radio>
              <v-radio :label="`Male`" value="Male"></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-sheet>
      <v-card-actions class="mt-4 paper--text">
        <span :class="textSize">BMI(Body Mass Index) </span>
        <v-spacer />
        <span :class="textSizeLarge + ' progressActive--text'"> {{ bmi }}</span>
      </v-card-actions>
      <v-card-actions class="paper--text">
        <span :class="textSizeSmall">Body Fat Percentage (Estimated) </span>
        <v-spacer />
        <span :class="textSizeLarge + ' progressActive--text'"> {{ bfp }}</span>
      </v-card-actions>
      <v-divider class="mt-2 pt-5" />
      <v-simple-table
        class="transparent silver--text"
        flat
        :style="charcoalTile"
      >
        <thead>
          <tr>
            <th width="70" :class="textSize + ' paper--text'">
              You
            </th>
            <th :class="textSizeSmall + ' silver--text'">Range</th>
            <th :class="textSizeSmall + ' silver--text'">Details</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="bmiRange in bmiRanges" :key="bmiRange.name">
            <td class="charcoal--text">
              {{ bmiRange.name }}
              <span v-if="bmi < bmiRange.max && bmi > bmiRange.min">
                <v-icon class="ml-2" x-small :color="bmiRange.color">
                  mdi-checkbox-blank-circle
                </v-icon>
              </span>
            </td>

            <td :class="textSizeXSmall">{{ bmiRange.range }}</td>
            <td :class="textSizeXSmall">{{ bmiRange.details }}</td>
          </tr>
        </tbody>
      </v-simple-table>

      <v-card-text class="silver--text">
        <h6 class="paper--text">What is BMI?</h6>
        <v-card-subtitle class=" px-0 pb-3 pt-0">
          The Body Mass Index or (BMI) is an indirect measure of a person's body
          fat using a mathematical formula that divides a person's weight by the
          square of their height. We use this result to determine whether or not
          a person is underweight, of a healthy weight, overweight or obese.
        </v-card-subtitle>
        <h6 class="paper--text">What Are The Limitations Of BMI?</h6>
        <v-card-subtitle class=" px-0 pb-3 pt-0">
          BMI does not correctly distinguish between body fat and muscle mass.
          As a side effect, people with muscular builds can get categorized as
          overweight or obese.
        </v-card-subtitle>
        <h6 class="paper--text">Why Even Use BMI?</h6>
        <v-card-subtitle class=" px-0 pb-3 pt-0">
          While BMI has some significant flaws, it is still a good measure for a
          lot of people, and it is easy to determine. Think about it as a quick
          and dirty way of determining where you are standing.
        </v-card-subtitle>
        <h6 class="paper--text">
          What About BFP (Body Fat Percentage)?
        </h6>
        <v-card-subtitle class=" px-0 pb-3 pt-0">
          The BFP (Body Fat Percentage) calculated on this page is a rough
          estimate derived from the calculated BMI, and thus inheriting any BMI
          flaws. For a more accurate BFP calculation, use our
          <a
            class="progressActive--text"
            href="/calculators/bfp"
            title="BFP(Body Fat Percentage Calculator"
            >BFP Calculator</a
          >.
        </v-card-subtitle>
      </v-card-text>
      <v-col cols="12">
        <a
          href="/pdf/infographics/WhatIsBMI-bfit.fit.pdf"
          title="What is BMI? Infographic"
          target="_blank"
        >
          <v-img
            :src="require('@/assets/infographics/WhatIsBMI-bfit.fit.png')"
            alt="What is BMI? Infographic"
            title="What is BMI? Infographic"
            contain
          />
        </a>
      </v-col>
    </v-sheet>
  </v-container>
</template>

<script>
import bmiRanges from '@/json/bmiranges.json'
import { appConfig } from '@/store/helpers.js'
import axios from 'axios'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    mySex: '',
    male: false,
    female: false,
    weight: 0,
    weightLbs: 0,
    weightKg: 0,
    heightMetric: 0,
    heightInches: 0,
    feet: 0,
    inches: 0,
    cm: 0,
    bmi: 0,
    bfp: 0,
    age: 0,
    showGender: true,
    bmiRanges: bmiRanges,
    measurements: [],
    measureList: [],
    basicMeasures: null,
    toLbs: 2.20462
  }),
  beforeMount() {
    if (this.loggedIn) {
      this.metric = this.useMetric
      this.mySex = this.sex
      this.loadBasic()
    }
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.age.focus())
    if (this.loggedIn) this.loadData()
  },
  validations: {},
  computed: {
    ...appConfig
  },
  methods: {
    launchCalculator(url) {
      this.$router.push({
        path: url
      })
    },
    setMeasures() {
      this.measurements = []
      let timestamp = this.now()
      let weightID = this.getMeasureId('Weight')
      let BMIID = this.getMeasureId('BMI')

      //parent measurement should go first
      this.measurements.push({
        measurementid: BMIID,
        imperial: this.bmi,
        metric: this.bmi,
        measured: timestamp,
        is_parent: true
      })

      this.measurements.push({
        measurementid: weightID,
        imperial: this.weightLbs,
        metric: this.weightKg,
        measured: timestamp,
        is_parent: false
      })
    },
    switchMe() {
      this.weight = 0
      this.inches = 0
      this.feet = 0
      this.cm = 0
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculate() {
      if (this.metric) {
        if (this.weight == 0 || this.cm < 30) {
          this.bmi = 0
          return
        }
        this.weightLbs = this.twoDigits(this.weight * this.toLbs)
        this.weightKg = this.twoDigits(this.weight)
        this.bmi = this.weight / Math.pow(this.cm / 100, 2)
        this.bmi = this.twoDigits(this.bmi)
      } else {
        this.weightLbs = this.twoDigits(this.weight)
        this.weightKg = this.twoDigits(this.weight / this.toLbs)
        if (this.weight == 0 || this.feet == 0) {
          this.bmi = 0
          return
        }
        this.heightInches = Number(this.feet) * 12 + Number(this.inches)
        this.bmi = (this.weight * 703) / (this.heightInches * this.heightInches)
        this.bmi = this.twoDigits(this.bmi)
      }

      if (this.age == 0 || (this.mySex != 'Male' && this.mySex != 'Female')) {
        this.bfp = 0
        return
      }

      if (this.mySex == 'Female') {
        this.bfp = 1.2 * this.bmi + 0.23 * this.age - 5.4
      } else {
        this.bfp = 1.2 * this.bmi + 0.23 * this.age - 16.2
      }
      this.bfp = this.twoDigits(this.bfp)
    },
    loadData() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/measurements/all', {})
          .then(response => {
            if (response.status == 200) {
              this.measureList = response.data.data
              this.loadBasic()
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    loadBasic() {
      {
        return axios
          .get(this.$store.state.config.baseURL + '/users/basic', {})
          .then(response => {
            if (response.status == 200) {
              this.basicMeasures = response.data.data
              if (this.basicMeasures.age > 0) this.age = this.basicMeasures.age
              let measures = JSON.parse(this.basicMeasures.measures)
              measures.forEach(el => {
                if (el.name == 'Weight') {
                  this.weightLbs = el.imperial
                  this.weightKg = el.metric
                  this.weight = this.metric ? this.weightKg : this.weightLbs
                }
                if (el.name == 'Height') {
                  this.heightInches = el.imperial
                  this.heightCm = el.metric
                  this.feet = Math.floor(this.heightInches / 12)
                  this.inches = Math.round(this.heightInches % 12)
                }
                this.calculate()
              })
            } else {
              this.$store.dispatch(
                'notification/addErrors',
                response.data.errors,
                5000,
                true,
                {
                  root: true
                }
              )
              return
            }
          })
      }
    },
    saveMeasurement() {
      if (!this.weight > 0) return
      this.setMeasures()
      return axios
        .post(this.$store.state.config.baseURL + '/measurements/multiple', {
          measurements: this.measurements
        })
        .then(response => {
          if (response.status == 200) {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addNotification',
              'Measurements were succesfully saved',
              2000,
              true,
              {
                root: true
              }
            )
          } else {
            this.measurements = {}
            this.$store.dispatch(
              'notification/addErrors',
              response.data.errors,
              5000,
              true,
              {
                root: true
              }
            )
            return
          }
        })
    }
  }
}
</script>
