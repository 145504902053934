<template>
  <v-container class="pa-2 ma-0">
    <BaseScreenHeader title="1RM Calculator" width="90%" screenInfo="CLC-001" />
    <v-card-text :class="textSizeXSmall + ' silver--text mt-2 pa-2'">
      To calculate your 1RM (One Rep Max), enter the weight and the number of
      repetitions(reps).
    </v-card-text>
    <v-sheet rounded color="charcoal charcoalTileMenu pa-2 paper--text">
      <v-card-actions class="pa-0">
        <v-spacer />
        <v-switch
          dark
          v-model="metric"
          :label="'Metric?'"
          @change="switchMe"
          color="progressActive"
          class="pa-0 ma-0"
        />
      </v-card-actions>
      <v-row>
        <v-col cols="7" md="3">
          <v-text-field
            dark
            :label="'Weight Lifted (' + unit(metric) + ')'"
            class="enlarged-input-small"
            ref="weight"
            v-model="weight"
            type="number"
            prepend-icon="mdi-weight"
            @input="calculate"
            @focus="$event.target.select()"
          />
        </v-col>
        <v-col cols="5" md="3">
          <v-text-field
            dark
            label="Reps"
            class="enlarged-input-small"
            ref="reps"
            v-model="reps"
            type="number"
            prepend-icon="mdi-counter"
            @input="calculate"
          />
        </v-col>
      </v-row>
    </v-sheet>
    <v-card-text :class="textSizeXSmall + ' silver--text mt-2 pa-2'">
      BFitr will calculate the absolute 1RM value, the 1RM value rounded to the
      nearest 5 (lbs or Kg) and the 1RM value rounded to the nearest 10 (lbs or
      Kg).
    </v-card-text>
    <template v-if="oneRM > 0">
      <v-sheet rounded color="charcoal charcoalTile px-2 paper--text">
        <v-row class="mt-2">
          <v-col cols="7">
            <span :class="textSize">One-Rep Max </span>
          </v-col>
          <v-spacer />
          <v-col align="right" cols="5">
            <span :class="textSize + ' progressActive--text'">
              {{ oneRM }} {{ unit(metric) }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <span class="title">To the nearest 5 </span>
          </v-col>
          <v-spacer />
          <v-col align="right" cols="5">
            <span :class="textSize + ' progressActive--text'">
              {{ oneRMFive }} {{ unit(metric) }}</span
            >
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="7">
            <span class="title">To the nearest 10 </span>
          </v-col>
          <v-spacer />
          <v-col align="right" cols="5">
            <span :class="textSize + ' progressActive--text'">
              {{ oneRMTen }} {{ unit(metric) }}
            </span>
          </v-col>
        </v-row>
      </v-sheet>
      <v-sheet
        rounded
        :class="
          textSizeXSmall +
            ' rounded-lg rounded-b-0 transparent silver--text mt-4'
        "
      >
        The table below calculates percentages of your one 1RM based on the
        values you've entered. You can use this as a guide when creating your
        workouts.
      </v-sheet>
      <v-card
        rounded
        flat
        class="transparent my-2 silver--text charcoalTileMenu"
        v-for="onermData in onermTypes"
        :key="onermData.value"
      >
        <v-card-actions class="py-0">
          <span :class="textSizeLarge + ' progressActive--text'">
            {{ onermData.value }}%
          </span>
          <v-spacer />
          <v-card-text class="py-0">
            <span class="progressActive--text">{{ onermData.goal }}</span>
            <v-spacer />
            <span :class="textSize + ' paper--text'">
              {{ oneRMPercentage(onermData.value) }}
              {{ unit(metric) }}
            </span>
            <v-spacer />
            <span :class="textSizeSmall + ' '">
              {{ onermData.sets }} sets of {{ onermData.reps }} reps each
            </span>
            <v-spacer />
            Speed: {{ onermData.speed }}
            <v-spacer />
            Rest: {{ onermData.rest }}
            <v-spacer />
          </v-card-text>
        </v-card-actions>
      </v-card>
      <v-row dense class="mt-10 silver--text">
        <v-col cols="12">
          <h6 class="paper--text">What is 1RM?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            1RM is the maximal weight an individual can lift for only one
            repetition using
            <span class="progressActive--text">proper form</span>. The
            one-repetition maximum (1RM) considered the "gold standard" for
            assessing the strength level of a person outside of a specialized
            lab.The 1RM test is often used by strength and conditioning coaches
            to assess strength, strength imbalances, and to evaluate the
            effectiveness of training programs.
          </v-card-subtitle>
          <h6 class="paper--text">What is multiple RM (RMX)?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            Multiple RM or RMX is an indirect way of measuring 1RM. Instead of
            lifting your maximum weight for one repetition, you will lift a
            smaller weight multiple times, and then you can calculate your 1RM
            using the calculator on this page.
          </v-card-subtitle>
          <h6 class="paper--text">How Do I Test My 1RM?</h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            <ul class="body-1">
              <li>
                Warm-up, completing some repetitions with a comfortable weight.
              </li>
              <li>
                Determine the 1RM (or any multiple RM, RMX) within four sets
                using rest periods between 3 and 5 minutes between sets.
              </li>
              <li>
                Start with a weight that is within your perceived ability to
                lift(usually falls between 50%–70% of your previous observed or
                estimated maximum).
              </li>
              <li>
                Increase resistance progressively in increments between 5 and 20
                until you can no longer complete the selected repetition(s) with
                good form. Perform al repetitions at the same speed of movement
                and range of motion to ensure consistency between sets.
              </li>
              <li>
                The final weight lifted successfully is recorded as the 1RM (or
                RMX)
              </li>
              <li>
                If you tested your RMX (two rep max, three rep max, five rep
                max, etc.), use the calculator on this page to calculate your
                1RM.
              </li>
            </ul>
          </v-card-subtitle>
          <h6 class="paper--text">
            What exercises are suitable 1RM testing?
          </h6>
          <v-card-subtitle :class="textSizeSmall + ' pb-3 pt-0 px-0'">
            The one-repetition maximum (1RM) testing is usually done for
            compound exercises, although they can be done on a variety of
            isolation exercises as well. See below for a list of exercises
            commonly 1RM tested.
          </v-card-subtitle>
          <v-simple-table class="pt-3 mx-0 transparent silver--text">
            <thead class="">
              <tr>
                <th class="text-left title progressActive--text">
                  Exercise
                </th>
                <th class="text-left title progressActive--text">Type</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="onermData in onermExercises" :key="onermData.text">
                <td class="text-left caption">{{ onermData.text }}</td>
                <td class="text-left caption">{{ onermData.type }}</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-col>
        <v-col cols="12">
          <a
            href="/pdf/infographics/HowToTest1RM-bfit.fit.pdf"
            title="How To Test 1RM? Infographic"
            target="_blank"
          >
            <v-img
              :src="require('@/assets/infographics/HowToTest1RM-bfit.fit.png')"
              alt="How To Test 1RM? Infographic"
              title="How To Test 1RM? Infographic"
              contain
            />
          </a>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import onermTypes from '@/json/onermtypes.json'
import onermExercises from '@/json/onermexercises.json'
import util from '@/mixins/util.js'

export default {
  mixins: [util],
  data: () => ({
    metric: false,
    weight: 0,
    reps: 0,
    oneRM: 0,
    oneRMFive: 0,
    oneRMTen: 0,
    onermTypes: onermTypes,
    onermExercises: onermExercises
  }),
  beforeMount() {
    if (this.loggedIn) this.metric = this.useMetric
  },
  mounted() {
    this.scrollToTop()
    this.$nextTick(() => this.$refs.weight.focus())
  },
  methods: {
    switchMe() {
      this.weight = 0
      this.$nextTick(() => this.$refs.weight.focus())
    },
    calculate() {
      if (!(this.reps > 0) || !(this.weight > 0)) {
        this.oneRM = 0
        this.oneRMFive = 0
        this.oneRMTen = 0
      } else {
        this.oneRM = this.weight * (1 + this.reps / 30)
        this.oneRM = Math.floor(this.oneRM * 100) / 100
        this.oneRMFive = Math.round(this.oneRM / 5) * 5
        this.oneRMTen = Math.round(this.oneRM / 10) * 10
      }
    },
    oneRMPercentage(val) {
      let calc = this.oneRM * (val / 100)
      calc = Math.round(calc / 5) * 5
      return calc
    }
  }
}
</script>
